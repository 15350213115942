<template>
  <div>
    <div class="text-right py-3 w-full">
      <el-button
        v-show="createBtn"
        size="small"
        @click="handleCreateClick"
        type="primary"
        >{{ $t("btn.cbn") }}
      </el-button>
    </div>
    <div class="groupContainer">
      <div class="head_item flex justify-between">
        <div class="left">
          <span>{{ $t("ifm.booked") }} </span>
          <div class="tag">{{ bookedCount }} </div>
        </div>
        <div
          class="right transition-transform"
          @click="bookedList.collapse = !bookedList.collapse"
          :style="{
            transform: bookedList.collapse ? 'rotate(0)' : 'rotate(180deg)',
          }"
        >
          <svg-icon icon-class="upArrow" />
        </div>
      </div>
      <div class="tableList">
        <collapse-transition :show="bookedList.collapse">
          <el-table :data="bookedList.tableData" :style="{ width: '100%' }" @row-click="handleCellClick">
            <el-table-column width="20" />
            <el-table-column sortable width="170" align="left" :label="$t('table.customer')" prop="customerCompanyName"/>
            <el-table-column sortable width="180" align="left" :label="$t('table.barge')" prop="bargeNameLabel"/>
            <el-table-column sortable width="170" align="left" :label="$t('table.product')" prop="product"
              v-if="userInfo.userType == 2" />
            <el-table-column sortable width="180" align="left" :label="$t('table.quantity')" prop="nominatedQtyMax"/>
            <el-table-column sortable width="200" align="left" :label="$t('table.brt')" prop="bargeReadiness">
              <template slot-scope="scope">
                <div class="flex items-center" v-if="scope.row['bargeReadiness']">
                  <svg-icon class="calendar" iconClass="calendar" />
                  <span style="padding-left: 5px">
                    {{ scope.row["bargeReadiness"] }}
                  </span>
                </div>
                <div v-else>-</div>
              </template>
            </el-table-column>
            <el-table-column sortable width="180" align="left" :label="$t('table.pilot')" prop="typeOfPilot">
            </el-table-column>
            <el-table-column sortable width="200" align="left" :label="$t('table.bat')" prop="berthAssignTiming">
              <template slot-scope="scope">
                <div class="flex items-center" v-if="scope.row['berthAssignTiming']">
                  <svg-icon class="calendar" iconClass="calendar" />
                  <span style="padding-left: 5px">
                    {{ scope.row["berthAssignTiming"] }}
                  </span>
                </div>
                <div v-else>-</div>
              </template>
            </el-table-column>
            <el-table-column sortable width="120" class="jettyNo" align="left" :label="$t('table.jettyNo')" prop="jettyNo">
              <template slot-scope="scope">
                <span style="color: #008CDB" v-if="scope.row['jettyNo']">
                  {{ scope.row["jettyNo"] }}
                </span>
                <span v-else> - </span>
              </template>
            </el-table-column>
          </el-table>
        </collapse-transition>
      </div>
    </div>
    <div class="groupContainer">
      <div class="head_item flex justify-between">
        <div class="left">
          <span>{{ $t("ifm.jA") }} </span>
          <div class="tag">{{ assignedCount }}</div>
        </div>
        <div
          class="right transition-transform"
          @click="assignedList.collapse = !assignedList.collapse"
          :style="{
            transform: assignedList.collapse ? 'rotate(0)' : 'rotate(180deg)',
          }"
        >
          <svg-icon icon-class="upArrow" />
        </div>
      </div>
      <div class="tableList">
        <collapse-transition :show="assignedList.collapse">
          <el-table
            :data="assignedList.tableData"
            :style="{ width: '100%' }"
            @row-click="handleCellClick"
          >
            <el-table-column width="20" />
            <el-table-column sortable
              width="170"
              align="left"
              :label="$t('table.customer')"
              prop="customerCompanyName"
            />
            <el-table-column sortable
              prop="bargeNameLabel"
              :label="$t('table.barge')"
              width="180"
              align="left"
            />
            <el-table-column sortable
              prop="product"
              v-if="userInfo.userType == 2"
              :label="$t('table.product')"
              width="170"
              align="left"
            />
            <el-table-column sortable
              prop="nominatedQtyMax"
              :label="$t('table.quantity')"
              width="180"
              align="left"
            />
            <el-table-column sortable  prop="bargeReadiness" :label="$t('table.brt')" width="200" align="left">
              <template slot-scope="scope">
                <div
                  class="flex items-center"
                  v-if="scope.row['bargeReadiness']"
                >
                  <svg-icon class="calendar" iconClass="calendar" />
                  <span style="padding-left: 5px">
                    {{ scope.row["bargeReadiness"] }}
                  </span>
                </div>
                <div v-else>-</div>
              </template>
            </el-table-column>
            <el-table-column sortable
              :label="$t('table.pilot')"
              prop="typeOfPilot"
              align="left"
              width="180"
            >
            </el-table-column>
            <el-table-column sortable prop="berthAssignTiming" :label="$t('table.bat')" width="200" align="left">
              <template slot-scope="scope">
                <div
                  class="flex items-center"
                  v-if="scope.row['berthAssignTiming']"
                >
                  <svg-icon class="calendar" iconClass="calendar" />
                  <span style="padding-left: 5px">
                    {{ scope.row["berthAssignTiming"] }}
                  </span>
                </div>
                <div v-else>-</div>
              </template>
            </el-table-column>
            <el-table-column sortable width="120" 
              :label="$t('table.jettyNo')"
              class="jettyNo"
              align="left"
              prop="jettyNo"
            >
              <template slot-scope="scope">
                <span style="color: #008CDB" v-if="scope.row['jettyNo']">
                  {{ scope.row["jettyNo"] }}
                </span>
                <span v-else> - </span>
              </template>
            </el-table-column>
          </el-table>
        </collapse-transition>
      </div>
    </div>
    <div class="groupContainer">
      <div class="head_item flex justify-between">
        <div class="left">
          <span>{{ $t("ifm.pji") }} </span>
          <div class="tag">{{ pendingJettyCount }}</div>
        </div>
        <div
          class="right transition-transform"
          @click="pendingJettyList.collapse = !pendingJettyList.collapse"
          :style="{
            transform: pendingJettyList.collapse
              ? 'rotate(0)'
              : 'rotate(180deg)',
          }"
        >
          <svg-icon icon-class="upArrow" />
        </div>
      </div>
      <div class="tableList">
        <collapse-transition :show="pendingJettyList.collapse">
          <el-table
            @row-click="handleCellClick"
            :data="pendingJettyList.tableData"
            :style="{ width: '100%' }"
          >
            <el-table-column width="20" />
            <el-table-column sortable
              width="170"
              :label="$t('table.customer')"
              align="left"
              prop="customerCompanyName"
            />
            <el-table-column sortable
              prop="bargeNameLabel"
              :label="$t('table.barge')"
              width="180"
              align="left"
            />
            <el-table-column sortable
              prop="product"
              v-if="userInfo.userType == 2"
              :label="$t('table.product')"
              width="170"
              align="left"
            />
            <el-table-column sortable
              prop="nominatedQtyMax"
              :label="$t('table.quantity')"
              width="180"
              align="left"
            />
            <el-table-column sortable  prop="bargeReadiness" :label="$t('table.brt')" width="200" align="left">
              <template slot-scope="scope">
                <div
                  class="flex items-center"
                  v-if="scope.row['bargeReadiness']"
                >
                  <svg-icon class="calendar" iconClass="calendar" />
                  <span style="padding-left: 5px">
                    {{ scope.row["bargeReadiness"] }}
                  </span>
                </div>
                <div v-else>-</div>
              </template>
            </el-table-column>
            <el-table-column sortable
              :label="$t('table.pilot')"
              prop="typeOfPilot"
              align="left"
              width="180"
            >
            </el-table-column>
            <el-table-column sortable prop="berthAssignTiming" :label="$t('table.bat')" width="200" align="left">
              <template slot-scope="scope">
                <div
                  class="flex items-center"
                  v-if="scope.row['berthAssignTiming']"
                >
                  <svg-icon class="calendar" iconClass="calendar" />
                  <span style="padding-left: 5px">
                    {{ scope.row["berthAssignTiming"] }}
                  </span>
                </div>
                <div v-else>-</div>
              </template>
            </el-table-column>
            <el-table-column sortable width="120" prop="jettyNo"
              :label="$t('table.jettyNo')"
              class="jettyNo"
              align="left"
            >
              <template slot-scope="scope">
                <span style="color: #008CDB" v-if="scope.row['jettyNo']">
                  {{ scope.row["jettyNo"] }}
                </span>
                <span v-else> - </span>
              </template>
            </el-table-column>
          </el-table>
        </collapse-transition>
      </div>
    </div>
    <div class="groupContainer">
      <div class="head_item flex justify-between">
        <div class="left">
          <span>{{ $t("ifm.pbrt") }} </span>
          <div class="tag">{{ pendingBargeCount }}</div>
        </div>
        <div
          class="right transition-transform"
          @click="pendingBargeList.collapse = !pendingBargeList.collapse"
          :style="{
            transform: pendingBargeList.collapse
              ? 'rotate(0)'
              : 'rotate(180deg)',
          }"
        >
          <svg-icon icon-class="upArrow" />
        </div>
      </div>
      <div class="tableList">
        <collapse-transition :show="pendingBargeList.collapse">
          <el-table
            :data="pendingBargeList.tableData"
            :style="{ width: '100%' }"
            @row-click="handleCellClick"
          >
            <el-table-column  width="40" label="No">
              <template slot-scope="scope">
                <div class="text-center">{{ scope.$index + 1 }}</div>
              </template>
            </el-table-column>
            <el-table-column sortable
              width="170"
              :label="$t('table.customer')"
              align="left"
              prop="customerCompanyName"
            />
            <el-table-column sortable
              prop="bargeNameLabel"
              :label="$t('table.barge')"
              width="180"
              align="left"
            />
            <el-table-column sortable
              prop="product"
              v-if="userInfo.userType == 2"
              :label="$t('table.product')"
              width="170"
              align="left"
            />
            <el-table-column sortable
              prop="nominatedQtyMax"
              :label="$t('table.quantity')"
              width="180"
              align="left"
            />
            <el-table-column  prop="bargeReadiness" sortable :label="$t('table.brt')" width="200" align="left">
              <template slot-scope="scope">
                <div
                  class="flex items-center"
                  v-if="scope.row['bargeReadiness']"
                >
                  <svg-icon class="calendar" iconClass="calendar" />
                  <span style="padding-left: 5px">
                    {{ scope.row["bargeReadiness"] }}
                  </span>
                </div>
                <div v-else>-</div>
              </template>
            </el-table-column>
            <el-table-column sortable
              :label="$t('table.pilot')"
              prop="typeOfPilot"
              width="180"
              align="left"
            >
            </el-table-column>
            <el-table-column sortable  prop="berthAssignTiming" :label="$t('table.bat')" width="200" align="left">
              <template slot-scope="scope">
                <div
                  class="flex items-center"
                  v-if="scope.row['berthAssignTiming']"
                >
                  <svg-icon class="calendar" iconClass="calendar" />
                  <span style="padding-left: 5px">
                    {{ scope.row["berthAssignTiming"] }}
                  </span>
                </div>
                <div v-else>-</div>
              </template>
            </el-table-column>
            <el-table-column sortable width="120" prop="jettyNo"
              :label="$t('table.jettyNo')"
              class="jettyNo"
              align="left"
            >
              <template slot-scope="scope">
                <span style="color: #008CDB" v-if="scope.row['jettyNo']">
                  {{ scope.row["jettyNo"] }}
                </span>
                <span v-else> - </span>
              </template>
            </el-table-column>
          </el-table>
        </collapse-transition>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from "@/components/SvgIcon.vue";
import CollapseTransition from "@/components/transitions/CollapseTransition.vue";
import { getBoadDashboardList } from "@/services";
import { mapState } from "vuex";

const columnKey = [
  "customer",
  "product",
  "quantity",
  "bargeName",
  "typeOfPilot",
  "quantity",
];

export default {
  components: { SvgIcon, CollapseTransition },
  name: "customer-create-list",
  data() {
    return {
      bookedCount:0,
      assignedCount: 0,
      pendingJettyCount: 0,
      pendingBargeCount: 0,
      bookedList: {
        collapse: true,
        tableData: [],
      },
      assignedList: {
        collapse: true,
        tableData: [],
      },
      pendingJettyList: {
        collapse: true,
        tableData: [],
      },
      pendingBargeList: {
        collapse: true,
        tableData: [],
      },
      createBtn: false,
    };
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  activated() {
    this.getList();
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      if ((this.userInfo.userType == 2 || this.userInfo.role == 5) && this.userInfo.role != 4 ){
        this.createBtn = true;
      }
      getBoadDashboardList().then((res) => {
        let data = res.data;
        this.bookedCount = data[3];
        this.assignedCount = data[2];
        this.pendingJettyCount = data[1];
        this.pendingBargeCount = data[0];
        let list = data.list;
        list = list.map((item) => {
          // add default in null
          for (let key in item) {
            if (columnKey.includes(key)) {
              item[key] = item[key] !== null ? item[key] : "-";
            }
          }
          if (item["vesselName"]) {
            item[
              "bargeNameLabel"
            ] = `${item["bargeName"]}(${item["vesselName"]})`;
          } else {
            item["bargeNameLabel"] = `${item["bargeName"]}`;
          }

          return item;
        });

        let bookedList = list.filter((item) => item["status"] == 2 && item['loadingType'] != null && item['blockNomination'] == '2'); 
        let assignedList = list.filter((item) => item["status"] == 2 && item['loadingType'] == null && item['blockNomination'] < '2');
        let pendingJettyList = list.filter((item) => item["status"] == 1);
        let pendingBargeList = list.filter((item) => item["status"] == 0);
        this.bookedList.tableData = bookedList.slice();
        this.assignedList.tableData = assignedList.slice();
        this.pendingJettyList.tableData = pendingJettyList.slice();
        this.pendingBargeList.tableData = pendingBargeList.slice();
      });
    },
    handleCreateClick() {
      this.$router.push({
        path: "/barging/index",
        query: {
          pageStatus: 1,
        },
      });
      sessionStorage.setItem("pageStatus", 1);
      sessionStorage.setItem("editData", JSON.stringify({}));
    },
    handleCellClick(row) {
      this.$router.push({
        path: "/barging/index",
        query: {
          id: row.id,
          pageStatus: 1,
        },
      });
      sessionStorage.setItem("pageStatus", 1);
      sessionStorage.setItem("editData", JSON.stringify(row));
    },
  },
};
</script>

<style lang="scss" scoped>
.justify-end {
  // margin-top: 38px;
  // margin-bottom: 30px;
  padding-right: 5px;
}
.groupContainer {
  box-shadow: 0px 4px 4px 1px rgba(196, 196, 196, 0.1);
  margin-bottom: 10px;
  font-size: 11px;
  font-weight: 400;
  font-family: 'Roboto';
  color: #1D1B20;

  .head_item {
    padding: 10px 18px 10px 30px;
    background: #ffffff;
    box-shadow: 0px 4px 4px 1px rgba(196, 196, 196, 0.1);
    border-radius: 10px 10px 0px 0px;

    .left {
      display: flex;
      span {
        font-size: 14px;
        font-weight: 800;
        padding-top: 5px;
      }
      .tag {
        width: 28px;
        height: 28px;
        line-height: 28px;
        text-align: center;
        margin-left: 18px;
        background: #1D1B20;
        border-radius: 10px;
        color: white;
        font-size: 12px;
      }
    }
    .right {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 28px;
      height: 28px;
      border-radius: 50%;
      border: 1.5px solid #1D1B20;
      svg {
        width: 12px;
        height: 16px;
      }
    }
  }
}

::v-deep svg use {
  color: #1D1B20;
  background-color: #1D1B20;
}
::v-deep:root {
  --svgColor:#1D1B20;
}


.tableList {
  // box-sizing: border-box;
  // padding: 20px;
  // background: #f9fcfd;
  background-color: #E5ECF1;
  border-radius: 0px 0px 10px 10px;
  padding: 20px;
  //margin-bottom: 16px;
}
::v-deep .el-table, 
::v-deep .el-table thead, 
::v-deep .el-table thead tr, 
::v-deep .el-table th.el-table__cell {
  background-color: transparent;
}
::v-deep table {
  background-color: transparent;
}
::v-deep .el-table__row {
  // margin-top: 8px;
  background: white;
  box-shadow: 0px 4px 4px 1px rgba(196, 196, 196, 0.1);
  border-radius: 2px 2px 0px 0px;
}
::v-deep .el-table__body {
  border-collapse: separate;
  border-spacing: 0px 8px;
  background-color: transparent;
  .el-table__row {
    margin: 5px 0px 10px;
    background-color: white;
    .el-table__cell { 
      border-radius: 0px;
      padding: 20px 0px;
      .cell {
        font-size: 13px;
        font-weight: 900;
        color: #1D1B20;
        }
    }
    .el-table__cell:first-child { 
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
    .el-table__cell:last-child { 
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }

  }
}
.el-table::before {
  height: 0;
  transition-property: height;
}
/*
::v-deep .el-table__row > .el-table__cell > .cell {
  font-size: 14px;
  font-weight: 900;
  color: #1D1B20;
}
  */
::v-deep .el-table th.el-table__cell > .cell {
  font-size: 12px;
  color: #1D1B20;
  font-family: "Roboto";
  font-weight: 600;

  .sort-caret.ascending {
    border-bottom-color: #1D1B20;
  }
  .sort-caret.descending {
    border-top-color: #1D1B20;
  }
}
/*
::v-deep .el-table td.el-table__cell div > .calendar {
  width: 15px;
  height: 16px;
}
  */
::v-deep .has-gutter .el-table__cell {
  padding: 0;
  border-top: none !important;
}
::v-deep .has-gutter .el-table__cell .cell {
  line-height: 40px;
  transform: translateY(5px);
}
::v-deep .el-table__empty-block {
  margin: 5px 0px 10px;
  border-radius: 5px;
  background-color: white;
  .el-table__empty-text {
    color: #1D1B20;
    font-size: 14px;
    font-weight: 800;
  }
}
</style>
