<template>
  <transition @enter="onEnter" @leave="onLeave" @before-leave="onBeforeLeave">
    <div
      v-show="show"
      class="transitionContainer"
      ref="transitionContainer"
      :style="{ transitionDuration: duration + 'ms' }"
    >
      <slot />
    </div>
  </transition>
</template>
<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    duration: {
      type: Number,
      default: 300,
    },
  },
  data() {
    return {
      readlHeight: 0,
    };
  },
  methods: {
    onEnter(el) {
      setTimeout(() => {
        el.style.height = this.readlHeight + "px";
      });
    },
    onLeave(el) {
      setTimeout(() => {
        el.style.height = "0px";
      });
    },
    onBeforeLeave(el) {
      el.style.height = el.scrollHeight + "px";
      this.readlHeight = el.scrollHeight;
    },
    onBeforeEnterer(el) {
      el.style.height = "0px";
    },
  },
};
</script>
<style lang="scss" scoped>
.transitionContainer {
  transition-property: height;
  overflow: hidden;
}
</style>
